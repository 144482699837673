@use "../../global/" as *;

.c-form-check1 {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 5px;

  .form-check-input {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid $gray-900;
    transform: translateY(-1px);

    &:checked {
      background: none;
      &::before {
        content: "";
        display: block;
        width: 7px;
        height: 7px;
        background: $secondary-600;
        border-radius: 10rem;
      }
    }

    &:focus,
    &:focus-visible {
      box-shadow: $focus;
    }
  }

  .form-check-label {
    @include jp-text-sm;
    font-weight: 700;
    line-height: 1;
  }
}
