// Colors
$gray-100: #ffffff;
$gray-200: #f0f0f0;
$gray-300: #cccccc;
$gray-400: #b3b3b3;
$gray-500: #999999;
$gray-600: #808080;
$gray-700: #666666;
$gray-800: #4d4d4d;
$gray-900: #1a1a1a;
$primary-100: #f6e4f1;
$primary-200: #edc8e4;
$primary-300: #e4add6;
$primary-400: #db91c9;
$primary-500: #d276bb;
$primary-600: #c245a2;
$primary-700: #94317b;
$primary-800: #632052;
$primary-900: #311029;
$secondary-100: #ebe3f8;
$secondary-200: #d8c7f1;
$secondary-300: #c4aceb;
$secondary-400: #b190e4;
$secondary-500: #9d74dd;
$secondary-600: #773ed0;
$secondary-700: #5828a2;
$secondary-800: #3a1a6c;
$secondary-900: #1d0d36;
$error-100: #f5cccc;
$error-200: #ef4343;
$error-300: #c73838;

$primary-gradient-100: linear-gradient(231.45deg, #773ed0 1.08%, #c245a2 79.84%);

// Font Family
$font-family-en: "Roboto", sans-serif;
$font-family-jp: YakuHanJP_Noto, "Noto Sans JP", sans-serif;

// Font Size
$font-size-xxs: 10;
$font-size-xs: 12;
$font-size-sm: 14;
$font-size-md: 16;
$font-size-lg: 18;
$font-size-xl: 20;
$font-size-2xl: 24;
$font-size-3xl: 30;
$font-size-4xl: 36;
$font-size-5xl: 48;
$font-size-6xl: 60;
$font-size-7xl: 72;
$font-size-8xl: 96;

// Easing
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

// Focus
$focus: 0 0 0 4px rgba($secondary-500, 0.5);
