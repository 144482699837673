@use "../../global"as *;

.p-footer1 {
  padding: responsiveValueClamp(30, 60, 375, 768);
  background: $gray-100;

  * {
    margin: 0;
  }
}

.p-footer1__inner1 {}

.p-footer1__list1 {
  &>ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
    justify-content: center;

    &>li {
      &>a {
        color: $gray-900;
        text-decoration: none;
        font-size: responsiveValueClamp($font-size-xs, $font-size-sm, 375, 1440);
      }
    }
  }
}

.p-footer1__copy1 {
  text-align: center;
  display: block;
  font-size: responsiveValueClamp($font-size-xs, $font-size-sm, 375, 1440);
  margin: 20px 0 0;
}
