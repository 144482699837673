@use "../../global"as *;

.c-button1 {
  color: $gray-100;
  background: $gray-900;
  padding: 10px 40px;
  border-radius: 10rem;
  text-decoration: none;
  font-weight: 700;
  font-size: responsiveValueClamp($font-size-md, $font-size-lg, 375, 1440);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 0.7s $easeOutQuint;

  &:hover {
    background: $gray-800;
    color: $gray-100;
  }

  span {
    line-height: 1;
    transform: translateY(1px);
  }

  path {
    fill: $gray-100;
  }
}
