@use "../../global"as *;

.p-block4 {
  margin: 60px 0 0;
  padding: 0 responsiveValueClamp(30, 90, 375, 1024);

  @include mq(sm, min) {
    margin: responsiveValueClamp(60, 120, 640, 1024) 0 0;
  }
}

.p-block4__inner1 {
  max-width: 990px;
  margin: auto;
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    content: "";
    width: 394px;
    height: 310px;
    background: url("../images/block4_img1_1.svg") 0 0 no-repeat;

    @include mq(lg, min) {
      display: block;
      top: -70px;
      right: -140px;
    }
  }
}

.p-block4__title1 {
  display: grid;
  justify-content: center;
  text-align: center;
  margin: 0;
  gap: 5px;

  @include mq(lg, min) {
    justify-content: start;
    text-align: left;
  }
}

.p-block4__title1__mainLabel1 {
  font-size: responsiveValueClamp($font-size-4xl, $font-size-6xl, 375, 1440);
  font-family: $font-family-en;
  margin: 0;
  background: $primary-gradient-100;
  background-clip: text;
  color: transparent;
  font-weight: 400;
  position: relative;
  text-transform: lowercase;

  &::before {
    position: absolute;
    top: 0.65em;
    left: calc(100% + 30px);
    display: none;
    content: "";
    width: 200px;
    height: 1px;
    background: $gray-900;

    @include mq(lg, min) {
      display: block;
    }
  }
}

.p-block4__title1__subLabel1 {
  font-weight: 700;
  font-size: $font-size-sm + px;
}

.p-block4__main1 {
  position: relative;
  margin: responsiveValueClamp(30, 60, 375, 1024) 0 0;
}

.p-block4__table1 {
  overflow-x: scroll;

  @include mq(900px, min) {
    overflow-x: visible;
  }

  table {
    background: $gray-100;
    overflow: hidden;
    border-radius: responsiveValueClamp(10, 20, 375, 1024) responsiveValueClamp(10, 20, 375, 1024) 0 0;
    width: 100%;
    border-collapse: collapse;

    * {
      white-space: nowrap;
    }

    tr {
      th,
      td {
        &:first-child {
          background: $primary-400;
        }
      }
    }
  }

  thead {
    tr {
      &:first-child {
        th {
          color: $gray-100;
          font-family: $font-family-en;
          font-size: responsiveValueClamp($font-size-lg, $font-size-2xl, 375, 1440);
          font-weight: 400;
          text-align: center;
          padding: responsiveValueClamp(10, 15, 375, 1024);

          &:nth-of-type(2) {
            background: $primary-500;
          }
          &:nth-of-type(3) {
            background: $primary-600;
          }
          &:nth-of-type(4) {
            background: $primary-700;
          }
        }
      }

      &:last-child {
        td {
          font-weight: 700;
          text-align: center;
          padding: responsiveValueClamp(15, 30, 375, 1024) 30px;

          @include mq(lg, min) {
          }

          span {
            font-size: responsiveValueClamp($font-size-4xl, $font-size-5xl, 375, 1440);
            font-family: $font-family-en;
            background: $primary-gradient-100;
            background-clip: text;
            color: transparent;
            margin: 0 5px 0 0;
          }
        }
      }
    }
  }

  tbody {
    tr {
      &:nth-of-type(odd) {
        background: $gray-200;
      }
    }

    th {
      color: $gray-100;
      font-weight: 700;
      font-size: responsiveValueClamp($font-size-sm, $font-size-md, 375, 1440);
      padding: 15px 30px;
      text-align: center;
      border-top: 1px solid rgba($gray-100, 0.5);
    }

    td {
      font-size: responsiveValueClamp($font-size-md, $font-size-xl, 375, 1440);
      text-align: center;
      font-weight: 700;
      border-top: 1px solid $gray-300;
    }
  }
}

.p-block4__unit1 {
  background: $gray-200;
  margin: responsiveValueClamp(20, 40, 375, 1024) 0 0;
  padding: responsiveValueClamp(20, 40, 375, 1024) responsiveValueClamp(30, 60, 375, 1024);
  border-radius: 0 0 responsiveValueClamp(10, 20, 375, 1024) responsiveValueClamp(10, 20, 375, 1024);
}

.p-block4__unit1__title1 {
  margin: 0;
  font-weight: 700;
  font-size: responsiveValueClamp($font-size-lg, $font-size-xl, 375, 1440);
}

.p-block4__unit1__text1 {
  margin: responsiveValueClamp(10, 20, 375, 1024) 0 0;
  font-size: responsiveValueClamp($font-size-sm, $font-size-md, 375, 1440);
  text-align: justify;
}

.p-block4__button1 {
  max-width: 320px;
  margin: responsiveValueClamp(30, 60, 375, 1024) auto 0;
}
