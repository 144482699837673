@use "../../global"as *;

.p-block3 {
  position: relative;
  margin: 45px 0 0;
  padding: 0 responsiveValueClamp(30, 90, 375, 1024);

  @include mq(sm, min) {
    padding: 0;
    width: 80vw;
    margin: calc(responsiveValueClamp(80, 160, 375, 768) * -1) 0 0;
  }

  @include mq(md, min) {
    width: 70vw;
  }

  @include mq(lg, min) {
    width: 60vw;
  }

  @include mq(xl, min) {
    width: calc(50vw + 30px);
  }
}

.p-block3__inner1 {
  @include mq(sm, min) {
    background: $gray-100;
    padding: responsiveValueClamp(45, 60, 640, 1280) responsiveValueClamp(60, 125, 640, 1280);
    border-radius: 0 40px 0 0;
  }

  @include mq(xl, min) {
    display: grid;
    justify-content: end;
    padding-left: 0;
  }
}

.p-block3__text1 {
  color: $primary-700;
  font-weight: 700;
  font-size: responsiveValueClamp($font-size-xl, $font-size-3xl, 375, 1280);
  line-height: 1.3;
  text-align: center;
  margin: 0;

  @include mq(sm, min) {
    text-align: left;
  }
}

.p-block3__button1 {
  max-width: 420px;
  margin: responsiveValueClamp(20, 30, 375, 768) auto 0;

  @include mq(sm, min) {
    margin-left: 0;
  }
}
