@use "../../global"as *;

.p-block1 {
  position: relative;
  z-index: 1;
  margin: responsiveValueClamp(60, 90, 375, 1024) 0 0;
  padding: 0 responsiveValueClamp(30, 90, 375, 1024);

  img {
    width: 100%;
    height: auto;
  }
}

.p-block1__inner1 {
  max-width: 990px;
  margin: auto;
}

.p-block1__head1 {
  @include mq(xl, min) {
    display: flex;
    gap: 60px;
  }
}

.p-block1__head1__title1 {
  display: grid;
  justify-content: center;
  text-align: center;

  @include mq(xl, min) {
    justify-content: start;
  }
}

.p-block1__head1__title1__mainLabel1 {
  font-size: responsiveValueClamp($font-size-4xl, $font-size-6xl, 375, 1440);
  font-family: $font-family-en;
  margin: 0;
  background: $primary-gradient-100;
  background-clip: text;
  color: transparent;
  font-weight: 400;
  text-transform: lowercase;
}

.p-block1__head1__title1__subLabel1 {
  font-weight: 700;
  font-size: $font-size-sm + px;
}

.p-block1__head1__text1 {
  margin: 30px 0 0;
  font-size: responsiveValueClamp($font-size-sm, $font-size-lg, 375, 1440);
  line-height: 1.5;
  text-align: justify;
  position: relative;

  @include mq(xl, min) {
    margin: 0;
    border-left: 1px solid $gray-900;
    display: grid;
    align-items: center;
    padding: 0 0 0 60px;
  }

  &::before {
    display: block;
    content: "";
    width: 50px;
    height: 1px;
    background: $gray-900;
    margin: 0 auto 30px;

    @include mq(xl, min) {
      display: none;
    }
  }
}

.p-block1__list1 {
  list-style: none;
  margin: responsiveValueClamp(30, 90, 375, 1280) 0 0;
  padding: 0;
  display: grid;
  gap: responsiveValueClamp(30, 60, 375, 1280);

  @include mq(sm, min) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.p-block1__list1__item1 {
  border-radius: 20px;
  overflow: hidden;
  background: $primary-gradient-100;
  padding: 1px;
}

.p-block1__list1__item1__inner1 {
  border-radius: 20px;
  background: $gray-100;
  padding: responsiveValueClamp(20, 30, 375, 640) responsiveValueClamp(20, 30, 375, 640);

  @include mq(sm, min) {
    height: 100%;
    padding: responsiveValueClamp(20, 30, 375, 640) responsiveValueClamp(20, 30, 375, 640);
  }
}

.p-block1__list1__item1__image1 {
  margin: 0;
  padding: 20px 0;
  display: grid;
  justify-content: center;

  img {
    width: auto;
    height: responsiveValueClamp(90, 120, 375, 768);
  }
}

.p-block1__list1__item1__title1 {
  margin: responsiveValueClamp(20, 40, 375, 768) 0 0;
  font-weight: 700;
  font-size: responsiveValueClamp($font-size-md, $font-size-xl, 375, 1440);
  text-align: center;
}

.p-block1__list1__item1__text1 {
  margin: responsiveValueClamp(15, 20, 375, 768) 0 0;
  font-size: $font-size-sm + px;
  line-height: 1.5;
  text-align: justify;
}

.p-block1__list1__item1__link1 {
  text-align: center;
}
