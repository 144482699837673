@use "sass:map";
@use "media-query"as *;

// Hidden for media query
// ============================================================================

// How to Use:
// <p class="u-hidden-md-up">md値より広い幅で非表示</p>
// <p class="u-hidden-lg-down">lg値より狭い幅で非表示</p>

@each $bp in map.keys($screen) {
  .u-hidden-#{$bp}-up {
    @include mq($bp, min) {
      display: none;
    }
  }

  .u-hidden-#{$bp}-down {
    @include mq($bp) {
      display: none;
    }
  }
}
