@use "../global" as *;

.l-main1 {
  overflow: hidden;
  position: relative;

  @at-root {
    body.-under & {
      padding: responsiveValueClamp(40, 90, 375, 1024) 0;
    }
  }
}
