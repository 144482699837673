@use "../../global"as *;

.c-button2 {
  color: $gray-100;
  padding: 5px 0;
  border-radius: 10rem;
  text-decoration: none;
  font-weight: 700;
  font-size: $font-size-xs + px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  border: 1px solid $gray-100;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.7s $easeOutQuint;
  position: relative;

  &:hover {
    background: $gray-900;
    color: $gray-100;
    border-color: $gray-900;
  }

  span {
    line-height: 1;
    transform: translateY(1px);
  }

  .-arrowRight1 {
    transform: rotate(-90deg);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto;
    zoom: 0.7;

    path {
      fill: $gray-100;
    }
  }
}
