@use "../../global"as *;

.p-kv1 {
  overflow: hidden;
  position: relative;
  background: $primary-gradient-100;
  border-radius: 0 0 responsiveValueClamp(60, 200, 375, 1024) 0;
  padding: responsiveValueClamp(60, 90, 375, 640) responsiveValueClamp(30, 90, 375, 1024);

  @include mq(sm, min) {
    padding-top: 0;
    padding-bottom: 0;
    height: responsiveValueClamp(500, 900, 640, 1024);
  }

  * {
    margin: 0;
  }

  img {
    width: 100%;
    height: auto;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: url("../images/bg_type1_1.svg") 0 0 / cover no-repeat;
    opacity: 0.8;

    @include mq(sm, min) {
      background: url("../images/bg_type1_1.svg") center center / cover fixed;
    }
  }
}

.p-kv1__inner1 {
  display: grid;
  gap: 30px;
  max-width: 500px;
  margin: auto;

  @include mq(sm, min) {
    max-width: inherit;
    height: 100%;
    display: grid;
    align-items: center;
  }

  @include mq(md, min) {
    max-width: 1280px;
  }

  @include mq(lg, min) {
    position: relative;
  }
}

.p-kv1__main1 {
  display: grid;
  gap: 30px;
  position: relative;
  z-index: 10;

  @include mq(sm, min) {
    width: 80%;
    gap: responsiveValueClamp(20, 30, 640, 1024);
  }

  @include mq(lg, min) {
    width: 65%;
    margin: 100px 0 0;
  }

  @include mq(xl, min) {
    width: 49%;
  }
}

.p-kv1__main1__catch1 {
  img {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

.p-kv1__main1__text1 {
  color: $gray-100;
  text-align: justify;
  font-size: responsiveValueClamp($font-size-sm, $font-size-lg, 375, 1440);
  line-height: 1.5;

  @include mq(sm, min) {
    width: 50%;
  }

  @include mq(lg, min) {
    width: 65%;
  }
}

.p-kv1__main1__button1 {
  @include mq(sm, min) {
    max-width: 315px;
  }
}

.p-kv1__image1 {
  @include mq(sm, min) {
    position: absolute;
    top: 0;
    right: -25vw;
    margin: auto;
    width: 80%;
    height: 100%;
    display: grid;
    align-items: center;
  }

  @include mq(xl, min) {
    width: 60%;
    right: 0;
  }
}
