@use "../../global"as *;

.c-button3 {
  color: $gray-100;
  padding: 10px 30px;
  border-radius: 10rem;
  text-decoration: none;
  font-weight: 700;
  font-size: $font-size-md + px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  gap: 10px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.7s $easeOutQuint;
  position: relative;
  background: $primary-gradient-100;

  &:hover {
    background: $gray-900;
  }

  span {
    line-height: 1;
    transform: translateY(1px);
  }

  svg {
    fill: $gray-100;
  }
}
