@use "../../global"as *;

.p-block6 {
  padding: 0 responsiveValueClamp(30, 90, 375, 1024);
}

.p-block6__inner1 {
  max-width: 500px;
  padding: 30px responsiveValueClamp(20, 40, 375, 1024);
  border-radius: 10px;
  background: $gray-100;
  margin: 0 auto;

  @include mq(md, min) {
    padding: 40px;
  }
}

.p-block6__text1 {
  @include jp-text-sm;
  text-align: justify;
  margin: 0;

  @include mq(md, min) {
    @include jp-text-md;
  }
}

.p-block6__link1 {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 20px 0 0;

  @include mq(md, min) {
    margin: 30px 0 0;
  }

  & > span {
    transform: translateY(1px);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: $gray-100;
    background: $primary-gradient-100;
    border-radius: 10rem;
  }
}

.p-block6__category1 {
  display: flex;
  align-items: center;
  gap: 20px;
  background: $gray-200;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 0 0 30px;
}

.p-block6__msg1 {
  @include jp-text-sm;
  color: $error-300;
  background: $error-100;
  font-weight: 700;
  border-radius: 10px;
  padding: 15px;
}

.p-block6__form1 {
  @at-root {
    .p-block6:has(.p-block6__category1) & {
      display: none;
    }

    .p-block6 {
      &:has(#radio1:checked) #formPanel1 {
        display: block;
      }
      &:has(#radio2:checked) #formPanel2 {
        display: block;
      }
    }
  }
}

.p-block6__list1 {
  margin: 30px 0 0;
  display: grid;
  gap: 15px;
}

.p-block6__button1 {
  margin: 30px 0 0;

  @include mq(md, min) {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 40px 0 0;
  }
}

.p-block6__button1__link1 {
  display: block;
  color: #773ed0;
  background-color: transparent;
  text-decoration: underline;
  border: 0;
  margin: 10px 0 0;

  @include mq(md, min) {
    margin: 0;
  }

  &:hover {
    text-decoration: none;
  }
}
