@use "../global/"as *;

body {
  font-size: responsiveValueClamp($font-size-sm, $font-size-md, 375, 1200);
  font-family: $font-family-jp;
  line-height: 1.7;
  background: $gray-100;
  margin: 0;

  &.-under {
    background: $primary-gradient-100;
    min-height: 100vh;

    &::before {
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background: url("../images/bg_type1_1.svg") center center / cover no-repeat;
      opacity: 0.8;

      @include mq(sm, min) {
        background: url("../images/bg_type1_1.svg") center center / cover fixed;
      }
    }
  }
}

a {
  color: $primary-600;

  &:hover {
    color: $primary-800;
  }

  &:focus-visible {
    outline: #005fcc solid 2px;
    border-radius: 3px;
  }
}
