@use "../../global/" as *;

.p-heading1 {
  padding: 0 0 40px;
}

.p-heading1__logo1 {
  width: 180px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
  }
}

.p-heading1__title1 {
  @include jp-text-md;
  text-align: center;
  font-weight: 700;
  color: $gray-100;
  margin: 5px 0 0;
}
