@use "../../global" as *;

.p-header1 {
  width: 100%;
  height: 80px;
  z-index: 100;
  position: relative;

  @include mq(lg, min) {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
    padding: 30px 50px;
    height: 100px;
    max-width: 1440px;
    margin: auto;
  }
}

.p-header1__inner1 {
}

.p-header1__logo1 {
  line-height: 1;
  display: flex;
  align-items: center;
  margin: auto;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: responsiveValueClamp(30, 90, 375, 1024);
  margin: auto;
  width: responsiveValueClamp(160, 176, 375, 1024);
  aspect-ratio: 176/31;
  overflow: hidden;
  text-indent: -200%;
  background: url("../images/logo_wizmucloud1_2.svg") center center / cover no-repeat;

  @include mq(lg, min) {
    display: block;
    background: url("../images/logo_wizmucloud1_1.svg") center center / cover no-repeat;
    z-index: 10;
  }

  a {
    display: block;
  }
}

.p-nav1__button1 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: responsiveValueClamp(15, 60, 375, 1024);
  margin: auto;
  padding: 0;
  background: $gray-100;
  border: none;
  width: 50px;
  height: 50px;
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: z(menu-button);

  path {
    @include transition;
  }

  &[aria-expanded="true"] {
    background: $gray-900;

    path {
      fill: $gray-100;

      &:nth-of-type(1) {
        transform: rotate(45deg) translate(1px, -1px) scaleX(1.3);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: rotate(-45deg) translate(-12px, -4px) scaleX(1.3);
      }
    }
  }
}

.p-nav1__main1 {
  position: absolute;
  top: 65px;
  right: responsiveValueClamp(15, 60, 375, 1024);
  width: 70vw;
  background: $gray-900;
  z-index: z(menu-body);
  transition: all;
  transition-duration: 0.5s;
  transition-timing-function: $easeOutQuint;
  padding: 50px 30px;
  max-width: 300px;

  @include mq(lg, min) {
    top: 38px;
    right: 90px;
    width: auto;
    height: auto;
    padding: 0;
    max-width: inherit;
    background: none;
    display: flex;
    align-items: center;
    gap: 30px;
  }

  @include mq(xl, min) {
    right: inherit;
    left: 0;
    top: 32px;
    width: 100%;
    padding: 0 90px 0 90+175+40px;
    justify-content: space-between;
  }

  @at-root {
    .p-nav1__button1[aria-expanded="false"] + & {
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
    }
  }
}

.p-nav1__list1 {
  list-style: none;
  margin: 0;
  padding: 0;

  @include mq(lg, min) {
    display: flex;
    gap: 30px;
  }

  @include mq(xl, min) {
    gap: 0;
  }

  li {
    @include mq(lg, min) {
      border: none;
    }

    @include mq(xl, min) {
      border-left: 1px solid rgba($gray-100, 0.5);
    }

    &:last-child {
      @include mq(xl, min) {
        border-right: 1px solid rgba($gray-100, 0.5);
      }
    }
  }

  a {
    font-size: $font-size-md + px;
    font-family: $font-family-en;
    padding: 10px 0;
    text-align: center;
    display: block;
    text-decoration: none;
    color: $gray-100;
    position: relative;
    transition: background-color 0.7s $easeOutQuint;

    @include mq(lg, min) {
      padding: 0;
    }

    @include mq(xl, min) {
      padding: 5px 30px;
    }

    &::before {
      background: $gray-100;
      display: block;
      content: "";
      width: 0%;
      height: 2px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: width 0.7s $easeOutQuint;

      @at-root {
        .p-nav1__list1 > li > a:hover::before {
          width: 90%;
        }
      }
    }

    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      margin: auto;
    }
  }
}

.p-nav1__button2 {
  margin: 30px 0 0;

  @include mq(lg, min) {
    margin: 0;
    width: 150px;
  }
}
