@use "../../global"as *;

.p-block7 {
  padding: 0 responsiveValueClamp(30, 90, 375, 1024);
}

.p-block7__inner1 {
  max-width: 800px;
  padding: responsiveValueClamp(30, 60, 375, 1280) responsiveValueClamp(20, 60, 375, 1280);
  border-radius: 10px;
  background: $gray-100;
  margin: 0 auto;
}

.p-block7__link1 {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 30px 0 0;

  @include mq(md, min) {
    margin: 30px 0 0;
  }

  &>span {
    transform: translateY(1px);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: $gray-100;
    background: $primary-gradient-100;
    border-radius: 10rem;
  }
}
