@use "../../global"as *;

.c-link1 {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  margin: 15px 0 0;
  text-decoration: none;
}

.c-link1__label1 {
  text-decoration: underline;
}

.c-link1__icon1 {
  transform: translateY(1px);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: $gray-100;
  background: $primary-gradient-100;
  border-radius: 10rem;
  text-decoration: none;
}
