@use "../../global" as *;

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
  gap: 5px;
  padding: 10px 20px;
  font-size: $font-size-md + px;

  span {
    display: inline-block;
    margin: 3px 0 0;
    font-size: $font-size-xs + px;
  }

  &.btn-sm {
    font-size: $font-size-xs + px;
    padding: 5px 10px;
    gap: 2.5px;

    span {
      margin: 1px 0 0;
      font-size: $font-size-xxs + px;
    }
  }

  &.btn-lg {
    font-size: $font-size-xl + px;
    padding: 15px 30px;
    gap: 5px;

    span {
      margin: 3px 0 0;
      font-size: $font-size-lg + px;
    }
  }

  &.btn-primary {
    background: $primary-600;
    border-color: $primary-600;

    &:active,
    &:hover {
      background: $primary-700;
      border-color: $primary-700;
    }

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($primary-600, 50%);
    }
  }

  &.btn-outline-primary {
    color: $primary-600;
    border-color: $primary-600;

    &:active,
    &:hover {
      color: $gray-100;
      background: $primary-600;
      border-color: $primary-600;
    }

    &:focus {
      box-shadow: 0 0 0 0.25rem rgba($primary-600, 50%);
    }
  }

  &.btn-black {
    color: $gray-100;
    background: $gray-900;
  }
}
