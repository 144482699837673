@use "../../global"as *;

.c-editor1 {
  display: grid;

  &>* {
    font-size: responsiveValueClamp(14, 16, 375, 1280);
    text-align: justify;
    margin: responsiveValueClamp(15, 30, 375, 1280) 0 0;
  }

  h2 {
    font-size: responsiveValueClamp(16, 20, 375, 1280);
    font-weight: 700;
    line-height: 1.5;
    position: relative;
    margin: responsiveValueClamp(45, 60, 375, 1280) 0 0;
    padding: responsiveValueClamp(15, 20, 375, 1280) 0 0;
    text-align: left;

    &::before {
      display: block;
      content: '';
      width: 50px;
      height: 5px;
      position: absolute;
      top: 0;
      left: 0;
      background: $primary-gradient-100;
    }
  }

  h3 {
    font-size: responsiveValueClamp(14, 18, 375, 1280);
    font-weight: 700;
    line-height: 1.5;
    position: relative;
    margin: responsiveValueClamp(30, 45, 375, 1280) 0 0;
    text-align: left;
    padding: 0 0 0 20px;

    &::before {
      display: block;
      content: '';
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      left: 0;
      background: $primary-gradient-100;
      transform: translateY(4px);
    }
  }

  ol,
  ul {
    padding: 0 0 0 1.3em;
    display: grid;
    gap: 10px;

    li {
      margin: 0;
    }
  }

  &>ol {
    padding: 0;
    list-style: none;
    counter-reset: my-counter1;

    &>li {
      counter-increment: my-counter1;
      display: grid;
      grid-template-columns: 20px 1fr;
      gap: 0 10px;

      &::before {
        display: grid;
        align-items: center;
        justify-content: center;
        content: counter(my-counter1);
        font-size: 12px;
        width: 20px;
        height: 20px;
        font-family: $font-family-en;
        color: $gray-100;
        background: $primary-gradient-100;
        border-radius: 10rem;
        line-height: 1;
        transform: translateY(2px);
      }

      &>ol {
        padding: 0;
        grid-column-start: 2;
        list-style: none;
        counter-reset: my-counter2;
        margin: 15px 0 0;

        &>li {
          counter-increment: my-counter2;
          display: grid;
          grid-template-columns: 20px 1fr;
          gap: 0 10px;
          align-items: start;

          &::before {
            display: grid;
            align-items: center;
            justify-content: center;
            content: counter(my-counter2) ".";
            font-size: 14px;
            font-family: $font-family-en;
            color: $secondary-700;
            line-height: 1;
            font-weight: 700;
            transform: translateY(5px);
          }
        }
      }
    }
  }

  table {
    border-bottom: 1px solid $gray-300;

    tr {
      border-top: 1px solid $gray-300;
      padding: 10px;
      margin: 0;
      display: block;

      @include mq(sm, min) {
        display: table-row;
        padding: 0;
      }
    }

    th,
    td {
      border: none;
      padding: 0;
      margin: 0;
      display: block;

      @include mq(sm, min) {
        display: table-cell;
        padding: 10px;
      }
    }

    th {
      @include mq(sm, min) {
        white-space: nowrap;
      }
    }

    td {
      @include mq(sm, min) {
        width: 100%;
      }
    }
  }
}
