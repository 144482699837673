@use "../../global"as *;

.p-block5 {
  overflow: hidden;
  position: relative;
  background: $primary-gradient-100;
  border-radius: responsiveValueClamp(60, 200, 375, 1024) 0 0 0;
  padding: responsiveValueClamp(60, 120, 375, 1024) responsiveValueClamp(30, 90, 375, 1024);
  margin: responsiveValueClamp(60, 90, 375, 1024) 0 0;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: url("../images/bg_type1_1.svg") 0 0 / cover no-repeat;
    opacity: 0.8;

    @include mq(sm, min) {
      background: url("../images/bg_type1_1.svg") center center / cover fixed;
    }
  }
}

.p-block5__inner1 {
  @include mq(lg, min) {
    display: flex;
    max-width: 880px;
    margin: auto;
    align-items: center;
  }
}

.p-block5__title1 {
  display: grid;
  justify-content: center;
  text-align: center;
  color: $gray-100;
  margin: 0;

  @include mq(xl, min) {
    justify-content: start;
  }

  @include mq(lg, min) {
    justify-content: start;
    text-align: left;
    padding: 0 120px 0 0;
  }
}

.p-block5__mainLabel1 {
  font-size: responsiveValueClamp($font-size-4xl, $font-size-6xl, 375, 1440);
  font-family: $font-family-en;
  margin: 0;
  font-weight: 400;
  text-transform: lowercase;
}

.p-block5__subLabel1 {
  font-weight: 700;
  font-size: $font-size-sm + px;
}

.p-block5__main1 {
  margin: responsiveValueClamp(30, 45, 375, 768) 0 0;

  @include mq(lg, min) {
    margin: 0;
    padding: 30px 0 30px 120px;
    border-left: 1px solid $gray-100;
  }
}

.p-block5__table1 {
  table {
    color: $gray-100;
    margin: auto;

    @include mq(lg, min) {
      margin: 0;
    }

    th,
    td {
      padding: 5px 0;
    }

    th {
      vertical-align: top;
      white-space: nowrap;
    }

    td {
      padding: 5px 0 5px 30px;

      & > span {
        display: block;
        font-size: $font-size-xs + px;
        opacity: 0.5;
        margin: 10px 0 0;
      }
    }
  }
}
