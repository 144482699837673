@use "../../global"as *;

.p-block2 {
  overflow: hidden;
  position: relative;
  background: $primary-gradient-100;
  border-radius: responsiveValueClamp(60, 200, 375, 1024) 0 0 0;
  padding: responsiveValueClamp(120, 210, 375, 1024) responsiveValueClamp(30, 90, 375, 1024) 60px;
  margin: calc(responsiveValueClamp(60, 90, 375, 1024) * -1) 0 0;

  @include mq(sm, min) {
    padding: responsiveValueClamp(120, 210, 375, 1024) responsiveValueClamp(30, 90, 375, 1024) responsiveValueClamp(60, 300, 375, 768);
  }

  img {
    width: 100%;
    height: auto;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background: url("../images/bg_type1_1.svg") 0 0 / cover no-repeat;
    opacity: 0.8;

    @include mq(sm, min) {
      background: url("../images/bg_type1_1.svg") center center / cover fixed;
    }
  }
}

.p-block2__inner1 {
  position: relative;
  max-width: 990px;
  margin: auto;
}

.p-block2__title1 {
  display: grid;
  justify-content: center;
  text-align: center;
  color: $gray-100;
  margin: 0;

  @include mq(xl, min) {
    justify-content: start;
  }

  @include mq(lg, min) {
    text-align: left;
    justify-content: start;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.p-block2__title1__mainLabel1 {
  font-size: responsiveValueClamp($font-size-4xl, $font-size-6xl, 375, 1440);
  font-family: $font-family-en;
  margin: 0;
  font-weight: 400;
  text-transform: lowercase;
}

.p-block2__title1__subLabel1 {
  font-weight: 700;
  font-size: $font-size-sm + px;
}

.p-block2__main1 {
  display: grid;
  gap: 45px;
  margin: 45px 0 0;

  @include mq(sm, min) {
    margin: responsiveValueClamp(45, 90, 640, 1024) 0 0;
  }

  @include mq(lg, min) {
    margin: 0;
    grid-template-columns: 1fr 1fr;
    gap: responsiveValueClamp(60, 120, 1024, 1280) responsiveValueClamp(60, 120, 1024, 1280);
  }
}

.p-block2__unit1 {
  @include mq(md, min) {
    display: grid;
    gap: responsiveValueClamp(45, 120, 768, 1280);
    grid-template-columns: 1fr 40vw;
  }

  @include mq(lg, min) {
    grid-column: span 2;
    grid-template-columns: 1fr 1fr;
  }
}

.p-block2__unit1__main1 {
  @include mq(md, min) {
    margin: 30px 0 0;
  }

  @include mq(lg, min) {
    margin: responsiveValueClamp(120, 180, 1024, 1280) 0 0;
  }
}

.p-block2__unit1__title1 {
  margin: 0;
  display: grid;
  grid-template-columns: responsiveValueClamp(60, 90, 375, 1024) 1fr;
  align-items: center;
  gap: responsiveValueClamp(15, 20, 375, 768);
  position: relative;

  @include mq(md, min) {
    grid-template-columns: 1fr;
    gap: responsiveValueClamp(30, 60, 768, 1280);
  }

  br {
    @include mq(sm, min) {
      display: none;
    }
  }
}

.p-block2__unit1__title1__number1 {
  width: responsiveValueClamp(60, 90, 375, 1024);
  height: responsiveValueClamp(60, 90, 375, 1024);
  background: $gray-100;
  border-radius: 10rem;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: responsiveValueClamp($font-size-3xl, $font-size-5xl, 375, 1440);
  color: $secondary-800;
  font-family: $font-family-en;
  font-weight: 400;
  margin: 0 auto;

  @include mq(md, min) {
    margin: 0;
  }

  &::before {
    display: none;
    content: "";
    width: 500px;
    height: 1px;
    position: absolute;
    top: 45px;
    left: 90px;
    background: $gray-100;

    @include mq(md, min) {
      display: block;
    }
  }
}

.p-block2__unit1__title1__mainLabel1 {
  font-size: responsiveValueClamp($font-size-xl, $font-size-3xl, 375, 1440);
  font-weight: 700;
  color: $gray-100;
  line-height: 1.5;
}

.p-block2__unit1__text1 {
  color: $gray-100;
  text-align: justify;
  font-size: responsiveValueClamp($font-size-sm, $font-size-md, 375, 1440);
  margin: responsiveValueClamp(20, 30, 375, 1280) 0 0;
}

.p-block2__unit1__image1 {
  margin: responsiveValueClamp(20, 40, 375, 640) auto 0;
  max-width: 400px;

  @include mq(md, min) {
    position: relative;
    margin: 0;
    max-width: inherit;
  }
}

.p-block2__unit2 {
  @include mq(lg, min) {
    grid-row: 2;
  }

  &:nth-of-type(2) {
    @include mq(lg, min) {
      grid-column: 1;
    }
  }

  &:nth-of-type(3) {
    @include mq(lg, min) {
      grid-column: 2;
    }
  }
}

.p-block2__unit2__title1 {
  margin: 0;
  display: grid;
  grid-template-columns: responsiveValueClamp(60, 90, 375, 1024) 1fr;
  gap: responsiveValueClamp(15, 20, 375, 768);
  align-items: center;
}

.p-block2__unit2__title1__number1 {
  width: responsiveValueClamp(60, 90, 375, 1024);
  height: responsiveValueClamp(60, 90, 375, 1024);
  background: $gray-100;
  border-radius: 10rem;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: responsiveValueClamp($font-size-3xl, $font-size-5xl, 375, 1440);
  color: $secondary-800;
  font-family: $font-family-en;
  font-weight: 400;
  margin: 0 auto;
}

.p-block2__unit2__title1__mainLabel1 {
  font-size: responsiveValueClamp($font-size-xl, $font-size-3xl, 375, 1440);
  font-weight: 700;
  color: $gray-100;
  line-height: 1.5;
}

.p-block2__unit2__main1 {
  margin: responsiveValueClamp(20, 30, 375, 1280) 0 0;

  &:has(.p-block2__unit2__image1) {
    @include mq(sm, min) {
      display: grid;
      gap: 30px;
      grid-template-columns: 1fr responsiveValueClamp(100, 140, 640, 1280);
    }
  }
}

.p-block2__unit2__text1 {
  color: $gray-100;
  text-align: justify;
  font-size: responsiveValueClamp($font-size-sm, $font-size-md, 375, 1440);
  margin: responsiveValueClamp(20, 30, 375, 1280) 0 0;

  @include mq(sm, min) {
    margin: 0;
    grid-column: 1;
    grid-row: 1;
  }
}

.p-block2__unit2__image1 {
  width: 100px;
  margin: 5px 0 0 20px;
  float: right;

  @include mq(sm, min) {
    float: none;
    margin: 0;
    width: auto;
    grid-column: 2;
  }

  @include mq(md, min) {
    transform: translateY(8px);
  }
}
