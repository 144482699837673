@use "../../global/" as *;

.c-form-control1 {
  &.-error {
    background: $error-100;
    border-color: $error-300;
    color: $error-300;
    &::placeholder {
      color: $error-300;
    }
  }

  &:focus,
  &:focus-visible {
    box-shadow: $focus;
    border-color: transparent;
  }
}
