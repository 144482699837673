@use "variables"as *;

// body
@mixin body-sm {
  font-size: $font-size-sm + px;
  line-height: 1.7;
}

@mixin body-md {
  font-size: $font-size-md + px;
  line-height: 1.7;
}

@mixin body-lg {
  font-size: $font-size-lg + px;
  line-height: 1.7;
}

// jp
@mixin jp-text-xxs {
  font-size: $font-size-xxs + px;
  line-height: 1.5;
}

@mixin jp-text-xs {
  font-size: $font-size-xs + px;
  line-height: 1.5;
}

@mixin jp-text-sm {
  font-size: $font-size-sm + px;
  line-height: 1.5;
}

@mixin jp-text-md {
  font-size: $font-size-md + px;
  line-height: 1.5;
}

@mixin jp-text-lg {
  font-size: $font-size-lg + px;
  line-height: 1.5;
}

@mixin jp-text-xl {
  font-size: $font-size-xl + px;
  line-height: 1.4;
}

@mixin jp-text-2xl {
  font-size: $font-size-2xl + px;
  line-height: 1.4;
}

@mixin jp-text-3xl {
  font-size: $font-size-3xl + px;
  line-height: 1.3;
}

@mixin jp-text-4xl {
  font-size: $font-size-4xl + px;
  line-height: 1.3;
}

@mixin jp-text-5xl {
  font-size: $font-size-5xl + px;
  line-height: 1.2;
}

@mixin jp-text-6xl {
  font-size: $font-size-6xl + px;
  line-height: 1.2;
}

@mixin jp-text-7xl {
  font-size: $font-size-7xl + px;
  line-height: 1.2;
}

@mixin jp-text-8xl {
  font-size: $font-size-8xl + px;
  line-height: 1.2;
}

// en
@mixin en-text-xxs {
  font-size: $font-size-xxs + px;
  font-family: $font-family-en;
  line-height: 1.4;
}

@mixin en-text-xs {
  font-size: $font-size-xs + px;
  font-family: $font-family-en;
  line-height: 1.4;
}

@mixin en-text-sm {
  font-size: $font-size-sm + px;
  font-family: $font-family-en;
  line-height: 1.4;
}

@mixin en-text-md {
  font-size: $font-size-md + px;
  font-family: $font-family-en;
  line-height: 1.4;
}

@mixin en-text-lg {
  font-size: $font-size-lg + px;
  font-family: $font-family-en;
  line-height: 1.4;
}

@mixin en-text-xl {
  font-size: $font-size-xl + px;
  font-family: $font-family-en;
  line-height: 1.3;
}

@mixin en-text-2xl {
  font-size: $font-size-2xl + px;
  font-family: $font-family-en;
  line-height: 1.2;
}

@mixin en-text-3xl {
  font-size: $font-size-3xl + px;
  font-family: $font-family-en;
  line-height: 1.2;
}

@mixin en-text-4xl {
  font-size: $font-size-4xl + px;
  font-family: $font-family-en;
  line-height: 1.2;
}

@mixin en-text-5xl {
  font-size: $font-size-5xl + px;
  font-family: $font-family-en;
  line-height: 1.1;
}

@mixin en-text-6xl {
  font-size: $font-size-6xl + px;
  font-family: $font-family-en;
  line-height: 1.1;
}

@mixin en-text-7xl {
  font-size: $font-size-7xl + px;
  font-family: $font-family-en;
  line-height: 1.1;
}

@mixin en-text-8xl {
  font-size: $font-size-8xl + px;
  font-family: $font-family-en;
  line-height: 1.1;
}
